// Responsiveness
$large-break: 1199px;
$medium-break: 991.5px;
$small-break: 767px;
$xtra-small-break: 575px;
$mobile-l-break: 426.5px;
$mobile-m-break: 375.5px;
$mobile-s-break: 320.5px;

// Body
// $body-bg: #f8fafc;
$body-bg: black;

// Typography
// $font-family-sans-serif: 'Red Hat Display', sans-serif;
$font-family-sans-serif: 'Roboto Mono', monospace;

$font-size-base: 0.9rem;
$line-height-base: 1.6;
$text-shadow-grey: #343a40;

// Footer
$navy: #21145f; // Footer bg & button
$lighter-navy: #230871; // Footer bottom
$sky-blue: #039be5; // button
$aqua: #29cbe4;
$hot-pink: #d6006d; // Social Media & Lines
$peach-pink: #ee2b65; // Underline under text
$fresh-orange: #ff5100; // Lines
$grey: #858585; // section title text
$white: #ffffff; // sub-section text
$red: #FD3D3A;
$green: #35C635;

$matrix-green: #00d11c;
$matrix-green-bright: #00ee20;
$matrix-green-dark: #00b418;
$text-color: #aaaaaa;
