@import "_variables";

// ********************************************************************************
//                                      TYPOGRAPHY
// ********************************************************************************
.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-black {
  color: black;
}

.font-white {
  color: $white;
}

.font-orange {
  color: $fresh-orange;
}

.font-pink {
  color: $hot-pink;
}

.green-text {
  color: #009914 !important;
}

// ********************************************************************************
//                                      GLOBAL
// ********************************************************************************

@keyframes fadeIn { from { opacity:0; opacity: 1\9; } to { opacity:0.8; } }
@keyframes fadeInFull { from { opacity:0; opacity: 1\9; } to { opacity:1; } }

#mainContent {
  background-color: black;

  #landing, #about, #work, #contact, #notFound, #works {
    .jumbotron {
      width: 100%;
      background-color: transparent;
    }
  
    .full-page {
      min-height: 100vh;
  
      .full-page-con {
        display: flex;
        min-height: 100vh !important;
        height: auto !important;
      }
    }
  }
}

// Full screen background video with overlay
.full-vid-con {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 0;

  .bg-vid {
    min-width: 100%;
    min-height: 100%;
    pointer-events: none;
  }

  .vid-overlay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 0;
    opacity: 0.5;
  }
}

// Work pages
#works {
  .full-page {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/images/about-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  .jumbotron {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .work-view-col {
    margin-left: auto;
    margin-right: auto;

    .work-view-box {
      border-radius: 0;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 30px 40px;

      .work-title {
        color: #009914;
        font-weight: 400;
        font-size: 20px;
      }

      .work-img {
        max-width: 700px;
        width: 100%;
        height: auto;
        display: block;
        margin: 50px auto;
      }
    }

    .work-section {
      .work-box-title {
        color: #009914;
        font-weight: 300;
        font-size: 18px;
      }

      .work-box {
        display: flex;
        // justify-content: center;
        justify-content: flex-start;
        margin-bottom: 40px;
    
        .work-text {
          line-height: 1.7;
          font-weight: 300;
          font-size: 16px;
          color: $text-color;
        }

        .work-text-link {
          transition-duration: 0.3s;
          color: $text-color;
          font-style: italic;
          font-weight: 500;
          font-size: 16px;
          text-decoration: none;

          &:hover {
            color: #009914;
          }

          &.no-hover {
            &:hover {
              color: $text-color;
            }
          }
        }
      }
    }

    .tech-stack {
      .work-box {
        justify-content: flex-start;
      }
    }
  }
}

@media(max-width: $small-break) {
  #works {
    .work-view-col {
      .work-view-box {
        padding: 30px 40px;
      }
  
      .work-section {
  
        .work-box {
          margin-bottom: 25px;
      
          .work-text {
            line-height: 1.7;
          }
        }
      }

    }
  }
}


// ********************************************************************************
//                                      LANDING
// ********************************************************************************

#landing {
  min-height: 100%;

  .home-col {
    z-index: 1;

    .intro-box {
      display: flex;
      justify-content: flex-start;
      max-width: 700px;

      .short-intro {
        font-family: 'Roboto Mono', monospace;
        font-weight: 300;
        font-size: 34px;
        line-height: 1.4;
        color: white;
      }
    }
  }
}

@media(min-width: 1920.5px) {
  #landing {
    .home-col {
      .intro-box {
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media(max-width: $large-break) {
  #landing {
    .home-col {
      .intro-box {
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media(max-width: $medium-break) {
  #landing {
    .full-page {
      .jumbotron {
        padding-top: 90px;
      }
    }

    .home-col {
      .intro-box {
        max-width: 620px;

        .short-intro {
          // text-align: center;
          font-size: 32px;
          line-height: 1.4;
        }
      }
    }
  }
}

@media(max-width: $small-break) {
  #landing {
    .home-col {
      .intro-box {
        max-width: 420px;

        .short-intro {
          font-size: 26px;
          line-height: 1.4;
        }
      }
    }
  }
}

@media(max-width: $xtra-small-break) {
  #landing {
    .home-col {
      .intro-box {
        max-width: 380px;

        .short-intro {
          font-size: 24px;
          line-height: 1.4;
        }
      }
    }
  }
}


// ********************************************************************************
//                                      ABOUT
// ********************************************************************************

#about{
  .full-page {
    background: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), url('../assets/images/about-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  .about-col {
    &:nth-child(2) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .about-box {
    .about-text {
      // color: #636b6f;
      // color: #018012;
      color: $text-color;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .img-box {
    height: 100%;
    padding: 0px 50px;

    .about-img {
      width: 100%;
      height: auto;

      display: block;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

@media(max-width: $large-break) {
  #about{
    .img-box {
      padding: 0px 30px;
    }
  }
}

@media(max-width: $medium-break) {
  #about{

    .jumbotron {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .img-box {
      padding: 30px 70px;

      .about-img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media(max-width: $small-break) {
  #about{
    .img-box {
      padding: 30px 30px;

      .about-img {
        min-width: 120px;
        max-width: 270px;
      }
    }

    .about-box {
      padding-top: 25px;

      .about-text {
        // font-size: 16px;
      }
    }
  }
}

@media(max-width: $xtra-small-break) {
  #about{
    .img-box {
      padding: 30px 15px;
    }
  }
}


// ********************************************************************************
//                                      CONTACT
// ********************************************************************************

#contact {
  .contact-col {
    margin-left: auto;
    margin-right: auto;
  }

  .contact-link-div {
    text-align: center;
    margin-bottom: 3px;

    &:first-child {
      margin-bottom: none;
    }

    .contact-text {
      text-align: center;
      color: #b6b5b5;
      font-size: 16px;
      margin-bottom: 25px;
    }

    .contact-link {
      transition-duration: 0.4s;
      color: #b6b5b5;
      font-size: 16px;
      text-decoration: none;

      &:hover {
        color: #009914;
        text-decoration: none;
      }
    }
  }
}


// ********************************************************************************
//                                  PAGE NOT FOUND
// ********************************************************************************

#notFound {
  .full-vid-con {
    .vid-overlay {
      opacity: 0.1;
    }
  }

  .not-found-col {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .error-text, .poem-text {
    text-align: center;
  }

  .error-text {
    color: red;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .poem-text {
    color: white;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 10px;
  }

  .btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .error-link {
      transition-duration: 0.3s;
      color: white;
      border-radius: 0;
      border: 1.5px solid $text-color;
      min-width: 85px;
      padding: 5px 15px;
      font-size: 16px;
      font-weight: 200;
      text-transform: uppercase;
      text-align: center;
      text-decoration: none;

      &:hover {
        color: red;
        border-color: red;
        padding: 5px 20px;
      }
    }
  }
}


// ********************************************************************************
//                                      WORK
// ********************************************************************************

#work {
  .full-page {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/images/about-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  .work-col {
    margin-left: auto;
    margin-right: auto;
  }

  #carouselWork {
    .carousel-item {
      .carou-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        transition-duration: 0.3s;
      }

      .carousel-caption {
        min-height: 221.5px;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 25%;
        padding-left: 120px;
        padding-right: 120px;
        background-color: rgba(0, 0, 0, 0.9);

        .carou-title {
          margin-top: 30px;
          font-size: 18px;
          font-weight: 400;
          color: #009914;
          text-transform: uppercase;
        }
      
        .carou-text {
          font-size: 16px;
          color: #c4c4c4;
          margin-bottom: 30px;
        }
      
        .carou-link-box {
          display: flex;
          justify-content: center;
      
          .carou-link {
            transition-duration: 0.3s;
      
            border: 2px solid $text-color;
            border-radius: 0;
            padding: 8px 10px;
      
            font-size: 16px;
            font-weight: 400;
            text-decoration: none;
            text-transform: uppercase;
            color: $text-color;
      
            &:hover {
              padding: 8px 16px;
              text-decoration: none;
              border-color: #009914;
              color: #009914;
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }

    }
  }
}

@media(max-width: $medium-break) {
  #work {
    #carouselWork {
      .carousel-item {
        .carousel-caption {
          min-height: 201.5px;
          bottom: 15%;
          padding-left: 100px;
          padding-right: 100px;

          .carou-title {
            margin-top: 20px;
          }
        }
      }
  
      .carousel-control-prev {
        justify-content: flex-start;
      }
   
      .carousel-control-next {
        justify-content: flex-end;
      }
    }
  }
}

@media(max-width: $small-break) {
  #work {
    .work-col {
      padding: 0;
    }

    #carouselWork {
      .carousel-item {
        height: 500px;
        // min-width: 315px;
        // max-width: 360px;
        // width: auto;
        width: 100%;

        .carou-img-wrapper {
          height: 500px;
          width: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-attachment: scroll;
        }


        .carousel-caption {
          bottom: 22%;
          min-height: 251.5px;
          padding-left: 50px;
          padding-right: 50px;

          .carou-title {
            margin-top: 15px;
            font-size: 16px;
          }
        
          .carou-text {
            font-size: 14px;
            margin-bottom: 25px;
          }
          
          .carou-link-box {
            .carou-link {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media(max-width: $xtra-small-break) {
  #work {
    #carouselWork {
      .carousel-item {
        min-width: 100%;
        // min-width: 280px;
        // max-width: 435px;
  
        .carou-img {
          height: 100%;
          // width: auto;
          width: 100%;
        }
  
        .carousel-caption {
          bottom: 25%;
          min-height: 230.5px;
          padding-left: 45px;
          padding-right: 45px;
          // min-width: 280px;
          // max-width: 435px;
          width: 100%;

          .carou-title {
            margin-top: 25px;
            font-size: 16px;
          }

          .carou-text {
            font-size: 14px;
            margin-bottom: 25px;
          }

          .carou-link-box {
            .carou-link {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media(max-width: $mobile-l-break) {
  #work {
    .work-col {
      width: 100%;
    }

    #carouselWork {
      margin-left: 30px;
      margin-right: 30px;

      .carousel-item {
        .carou-img {
        }

        .carousel-caption {
          padding-left: 40px;
          padding-right: 40px;

          .carou-title {
            margin-top: 25px;
            font-size: 15px;
          }

          .carou-text {
            font-size: 13px;
            margin-bottom: 25px;
          }

          .carou-link-box {
            .carou-link {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}