@import "_variables";

// ********************************************************************************
//                                      NAVBAR
// ********************************************************************************
.height-300 {
  height: 300px;
}

nav {
  padding-top: 16px;
  padding-bottom: 16px;
  transition-duration: 0.4s;
  height: 70px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
  position: fixed !important;
  width: 100%;
  box-shadow: none;
  border-bottom: none;

  .navbar-toggler {
    color: $grey !important;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    .navbar-toggler-icon {
      color: $grey !important;
    }

    &:hover {
      transition-duration: 0.4s;
      color: $matrix-green-dark !important;
    }
  }

  .navbar-nav {
    margin-left: auto;
  }

  .nav-link {
    color: #fff !important;
    transition-duration: 0.4s;
    text-decoration: none;
    font-weight: 100 !important;
    font-size: 16px;

    &:hover {
      color: $matrix-green-bright !important;
    }
    
    &.active {
      color: $matrix-green-bright !important;
    }
  }

  .navbar-brand {
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    font-weight: 100;
    text-transform: uppercase;
    color: #fff !important;
    transition: all 0.4s;
  
    &:hover {
      color: $matrix-green-bright !important;
    }
  }
}

@media(max-width: $medium-break) {
  nav {
    height: auto !important;
    background: #1a1a1a !important;

    &.navbar {  
      padding: 10px 20px !important;
    }

    .navbar-collapse {
      margin-top: 5px;
    }

    .nav-link {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }
}


// ********************************************************************************
//                              Transparent Navbar Pages
// ********************************************************************************

.transparent-pt {
  padding-top: 70px;
}


// ********************************************************************************
//                                   COLLAPSE NAVBAR
// ********************************************************************************
#navCollapser {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  max-height: 650%;
  height: auto;
  width: 100%;
  z-index: 120;
  cursor: pointer;
  border: none;
  border-radius: 0;
  background-color: transparent;

  &.collapsed {
    z-index: -1;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}


// ********************************************************************************
//                                      FOOTER
// ********************************************************************************

$footer-top-bg: #1a1a1a;
$footer-btm-bg: #1a1a1a;

footer {
  background-color: $footer-top-bg;
}

#footer-bottom {
  background-color: $footer-top-bg;

  #copyright-text {
    color: $grey;
    font-size: 12px;
    text-align: left;

    margin-top: 17px;
    margin-bottom: 17px;
  }

  .social-icons {
    display: inline-block;

    .github, .medium, .instagram {
      margin-right: 15px;
    }

    a {
      .social-icon {
        font-size: 15px;
        color: $grey;
        padding: 8px;
      }

      &:hover {
        .social-icon {
          transition-duration: 0.5s;
          color: $matrix-green-dark;
        }
      }
    }
  }

  .footer-btm-my {
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: flex-end;
  }
}

@media(max-width: $medium-break) {
  #footer-social, #footer-bottom {
    .footer-btm-my {
      justify-content: center;
    }
  }

  #footer-social {
    .social-icons {
      display: inline-block;
      padding-left: 0;
      margin-top: 20px;
      margin-bottom: 5px;

      .github, .medium, .instagram {
        margin-right: 15px;
      }

      a {
        .social-icon {
          font-size: 15px;
          color: $grey;
          padding: 8px;
        }

        &:hover {
          .social-icon {
            transition-duration: 0.5s;
            color: $matrix-green-dark;
          }
        }
      }
    }
  }

  #footer-bottom {
    #copyright-text {
      text-align: center;
    }
  }
}

// ********************************************************************************
//                                  SCROLL TOP BTN
// ********************************************************************************

.scroll-top-btn { 
  cursor: pointer;
  position: fixed;
  z-index: 99;
  bottom: 25px;
  right: 15%;
  border-radius: 50%;
  border: none;
  font-size: 30px;
  background-color: $matrix-green-dark;
  color: #212529;
  transition-duration: 0.3s;
  
  &:hover {
    background-color: #212529;
    color: $matrix-green-dark;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

@media(min-width: 1920.5px) {
  .scroll-top-btn { right: 25%; }
}

@media(max-width: $large-break) {
  .scroll-top-btn { right: 100px; }
}

@media(max-width: $small-break) {
  .scroll-top-btn { right: 50px; }
}
