// Variables
@import '_variables';

// Custom SCSS
@import 'base';
@import 'layout';
@import 'custom';

// Scrollbar
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: black;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(62, 62, 62, 0.7);
  border-radius: 25px;
}

html, body {
  height: 100%;
  font-family: $font-family-sans-serif;
}

#app {
  height: 100%;
}

body {
  background-color: black;
}

p, h1, h2, h3, h4, h5, h6, span {
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

